<template>    
    <Login v-if="$route.path === '/login'" :urlSearchParams="urlSearchParams" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/notfound'" />    
    <App v-else :theme="theme" :layout="layout"  />
    <Cargando ref="cargando"></Cargando>    
</template>

<script>
import {ref} from 'vue';
import App from './templates/apollo/App.vue';
import Error from './paginas/app/Error';
import Access from './paginas/app/Access';
import Login from './paginas/login/Login';
import NotFound from './paginas/app/NotFound';
import Cargando from '../../../../shared/src/componentes/base/common/cargando/Cargando.vue';

export default {
    data() {
        return {
            theme: 'blue',
            layout: 'layout-blue',
            cargando : ref(),
            urlSearchParams: new URLSearchParams(window.location.search)
        };
    },    
    methods: {        
        onThemeChange(theme) {
            this.theme = theme;
        },
        onLayoutChange(layout) {
            this.layout = layout;
        },
    },
    components: {
        App: App,
        Error: Error,
        Access: Access,
        Login: Login,
        NotFound: NotFound,
        Cargando
    },
};
</script>

<style scoped></style>
