<template>
    <Toast position="bottom-right" style="z-index: 8888888888888" />
    <body ref="bodyLogin" class="login-body" style="background: getUrlLoginImage() center top / cover no-repeat fixed; transition: background 1s ease-in-out 0s">
        <div class="mobile-banner">
            <img :src="getLogoPanelUrl" alt="prestige-layout" />
        </div>
        <div class="login-panel">
            <div class="logo">
                <img :src="getLogoPanelUrl" style="width: 80%" alt="prestige-layout" />
            </div>
        </div>
        <div class="login-card" v-if="!isForgetPass">
            <form @submit.prevent="handleSubmit(!v$.$invalid)">
                <div class="top-border"></div>
                <div class="login-content">
                    <img :src="getLogoUrl" style="width: 100px" />
                    <h1>Acceso al sistema</h1>
                    <p>Introducir nombre de usuario y contraseña.</p>
                    <div class="username-container">
                        <label :class="{ 'p-invalid': v$.email.$invalid && submitted }">E-mail</label>
                        <div class="login-input">
                            <InputText ref="emailControl" placeholder="Nombre de usuario" autocomplete="username" v-model="request.email" :class="{ 'p-invalid': v$.email.$invalid && submitted }" />
                        </div>
                        <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message }}</small>
                    </div>
                    <div v-if="isNeedMFA">
                        <label style="color: brown" :class="{ 'p-invalid': v$.securityCode.$invalid && submitted }">Código de seguridad</label>
                        <div class="login-input">
                            <InputText placeholder="Código de seguridad" v-model="request.securityCode" :class="{ 'p-invalid': v$.securityCode.$invalid && submitted }" />
                        </div>
                        <small v-if="(v$.securityCode.$invalid && submitted) || v$.securityCode.$pending.$response" class="p-error">{{ v$.securityCode.required.$message }}</small>
                    </div>
                    <div v-else class="password-container">
                        <label :class="{ 'p-invalid': v$.password.$invalid && submitted }">Password</label>
                        <div class="login-input">
                            <InputText
                                id="current-password"
                                autocomplete="current-password"
                                ref="pwdControl"
                                type="password"
                                placeholder="Contraseña"
                                v-model="request.password"
                                v-on:keyup.enter="onSubmitLogin"
                                toggle-mask
                                class="p-password"
                                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                            />
                            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ v$.password.required.$message }}</small>
                        </div>
                    </div>
                    <div class="forgetpassword-container">
                        <a href="#" class="forget-password" @click="isForgetPass = true">¿Ha olvidado su contraseña?</a>
                    </div>
                    <div>
                        <Button id="submitButton" type="submit" :loading="showLoading" @click="onSubmitLogin" label="Acceder" />
                        <span style="float: right; font-weight: bold; padding-bottom: 2px">{{ version }} </span>
                    </div>
                </div>
            </form>
        </div>

        <ForgotPassword v-if="isForgetPass" v-model:isForgetPassChild="isForgetPass" />
    </body>
    <Cargando ref="cargando"></Cargando>
</template>

<script lang="ts">
//Vue
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

//Vue validate
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';

//Componentes
import ForgotPassword from './ForgotPassword.vue';
import Cargando from '@frontcommon/shared/src/componentes/base/common/cargando/Cargando.vue';

//Interfaces
import { loginRequest } from '@frontcommon/shared/src/interfaces/nostromo/service/loginRequest';
import CookieService from '@frontcommon/shared/src/servicios/CookieService';
import parametrosLogin from '@frontcommon/shared/src/modelos/parametrosUrl/parametrosLogin';

//Utilidades
import Swal from 'sweetalert2/dist/sweetalert2.js';

//Constantes y Tipos enumerados
import { Actions } from '@frontcommon/shared/src/almacen/enums/StoreEnums';

//Helpers
import HelperUtils from '@frontcommon/shared/src/funciones/HelperUtils';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import HelperConfiguration from '@frontcommon/shared/src/funciones/HelperConfiguration';
import logoComp from '@frontcommon/shared/src/funcionescompartidas/logoComp';
import HelperUrlParameters from '@frontcommon/shared/src/funciones/HelperUrlParameters';

export default defineComponent({
    components: { ForgotPassword, Cargando },
    name: 'login',
    props: {
        urlSearchParams: URLSearchParams,
    },
    setup(props) {
        const { getLogoPanelUrl, getLogoUrl, ipublicPath, platform } = logoComp();

        const store = useStore();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        const bodyLogin = ref<HTMLElement | null>(null);
        const emailControl = ref<HTMLElement | null>(null);
        const pwdControl = ref<HTMLElement | null>(null);
        let isLoading = ref<boolean>(false);
        let index = 0;
        const isForgetPass = ref(false);
        const submitted = ref(false);
        const version = ref('');
        const cargando = ref();

        const request = ref<loginRequest>({
            sessionID: null,
            email: '',
            password: '',
            remoteApplication: 'Portalv2',
            remoteIp: '',
            requestedUrl: '',
            securityCode: '',
        });

        const customEmail = (value) => {
            return HelperCommon.validarEmail(value);
        };

        const rules = {
            password: {
                required: helpers.withMessage(
                    'El password es obligatorio',
                    requiredIf((...args) => {
                        var datos = args as any;
                        return !isNeedMFA.value && datos[args.length - 1].password;
                    })
                ),
            },
            email: {
                required: helpers.withMessage('El usuario es obligatorio', required),
                customEmail: helpers.withMessage('Correo no válido', customEmail),
            },
            securityCode: {
                required: helpers.withMessage(
                    'El código de seguridad es obligatorio',
                    requiredIf((...args) => {
                        var datos = args as any;
                        return isNeedMFA.value && datos[args.length - 1].securityCode;
                    })
                ),
            },
        };

        const v$ = useVuelidate(rules, request);

        const getVersion = () => {
            cargando.value.mostrar();
            HelperConfiguration.getVersion()
                .then((data) => {
                    version.value = data.version;
                    platform.value = data.platform;
                })
                .finally(() => {
                    cargando.value.ocultar();
                });
        };
        onMounted(() => {             
            HelperUrlParameters.getParametrosUrl(props?.urlSearchParams).then((parametrosUrl:parametrosLogin | null) => {                
                if (parametrosUrl) {
                    if (parametrosUrl.sessionId) {                                                       
                        store.dispatch(Actions.LOGIN_SESSIONID, parametrosUrl.sessionId);                        
                        redirectAfterLogin();                               
                    }                    
                    else if (parametrosUrl.user && parametrosUrl.password) {
                        request.value.email = parametrosUrl.user;
                        request.value.password = parametrosUrl.password;
                        onSubmitLogin();                                     
                    }                
                }
                Swal.close();
                getVersion();
                pageLoad();
            });            
        });

        const handleSubmit = (isFormValid) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }
        };

        const isValidLoginData = (): boolean => {
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        };
        const showLoading = computed(() => {
            return isLoading.value;
        });

        //Form submit function
        const onSubmitLogin = () => {
            if (isValidLoginData()) {
                setTimeout(() => {
                    isLoading.value = true;
                    cargando.value.mostrar();
                    store
                        .dispatch(isNeedMFA.value ? Actions.LOGINMFA : Actions.LOGIN, request.value)
                        .then((loginResponse) => {
                            if (loginResponse.sessionID) {
                                redirectAfterLogin();
                            }
                        })
                        .finally(() => {
                            isLoading.value = false;
                            cargando.value.ocultar();
                        });
                }, 500);
            }
        };

        const onSubmitForgotPassword = () => {
            // Dummy delay
            setTimeout(() => {
                // Send login request
                store.dispatch(Actions.FORGOT_PASSWORD, request.value).then(() => {
                    router.push({ name: 'mipanel' });
                });

                //Deactivate indicator
            }, 100);
        };

        function redirectAfterLogin() {            
            var redirectCookie = CookieService.getCookie(CookieService.COOKIE_REDIRECT_URL) ?? '';

            if (redirectCookie != null && redirectCookie != '') {
                CookieService.destroy(CookieService.COOKIE_REDIRECT_URL);
                // para las locales
                if (ipublicPath !== '/') {
                    router.push(redirectCookie.replace(ipublicPath + '', ''));
                } else {
                    router.push(redirectCookie);
                }
            } else {
                var initUrl = store.getters.initUrlPage;
                if (initUrl == '') {
                    const id = HelperUtils.newGuid();
                    router.push({ name: 'rfps', params: { id: id, operation: 'productos' } });
                } else {
                    router.push({ name: initUrl == 'default.aspx' ? 'mipanel' : initUrl });
                }
            }
        }

        function pageLoad() {
            carousel();
        }

        const getUrlLoginImages = (): string => {
            return `${ipublicPath}layout/images/pages/login/AunnaManager`;
        };
        const getUrlLoginImage = (): string => {
            return getUrlLoginImages() + '/bgw1.jpg';
        };

        function carousel() {
            if (router.currentRoute.value.path == '/login' && bodyLogin.value != null) {
                if (index > 5) {
                    index = 0;
                }
                index++;

                const urlLogo = getUrlLoginImages();

                if (index === 1) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw1.jpg) center top / cover no-repeat fixed';
                } else if (index === 2) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw2.jpg) center top / cover no-repeat fixed';
                } else if (index === 3) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw3.jpg) center top / cover no-repeat fixed';
                } else if (index === 4) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw4.jpg) center top / cover no-repeat fixed';
                } else {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw5.jpg) center top / cover no-repeat fixed';
                }
            }
            setTimeout(carousel, 30000);
        }

        const isNeedMFA = computed((): boolean => {
            return store.getters.isNeedMFA;
        });

        return {
            isNeedMFA,
            request,
            onSubmitLogin,
            onSubmitForgotPassword,
            submitButton,
            bodyLogin,
            carousel,
            pageLoad,
            isForgetPass,

            emailControl,
            pwdControl,
            showLoading,
            isLoading,
            ipublicPath,
            v$,
            isValidLoginData,
            submitted,
            handleSubmit,
            version,
            getLogoPanelUrl,
            getLogoUrl,
            getVersion,
            platform,
            cargando,
            getUrlLoginImage,
        };
    },
});
</script>

<style lang="scss" scoped></style>
