import {computed, inject, ref} from 'vue'

export default function logoComp(){

    const platform = ref ('')
    const ipublicPath = inject('publicPath');    
        
    const getLogoPanelUrl = computed(() =>{        
        return `${ipublicPath}layout/images/logo_aunna.png`;
    })

    const getLogoUrl = computed(() =>{          
        return `${ipublicPath}layout/images/logo_aunna.png`;
    })

    return {
        platform,
        ipublicPath,

        getLogoPanelUrl,
        getLogoUrl
    }
}